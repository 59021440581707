.alert {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    background-color: rgba(0,0,0,0.4);
    display: flex;
    align-items: center;
    justify-content: center;
}

.alert__container {
    background-color: rgba(0,0,0,0.8);
    padding: 2rem;
    width: 80%;
    max-width: 300px;
}

.alert__message {
    margin-top: 1rem;
    color: #fff;
    text-align: center;
    white-space: break-spaces;
    line-height: 1.5;
}

.alert__btn {
    background-color: #7067CF;
    display: block;
    cursor: pointer;
    border: 0;
    padding: 0.5rem 0.75rem;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
}

.alert__btn.confirm {
    background-color: #4cc33f;
}

.alert__btn.deny {
    background-color: red;
}

.alert__btn:hover,
.alert__btn:focus,
.alert__btn:active {
    outline: none;
}

.alert__icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.alert__icon svg {
    height: 65px;
    width: auto; 
}

.alert__buttons {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    padding-right: 1rem;
    padding-left: 1rem;
}